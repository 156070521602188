
// Vue reactivity
import { computed } from 'vue';

// icons
import { add, trashOutline, createOutline } from 'ionicons/icons';

// components
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,
        IonToolbar, IonHeader, IonTitle, IonButtons, IonButton, IonIcon, IonList, IonItem, IonLabel,
        IonItemSliding, IonItemOptions, IonItemOption,
        loadingController, } from '@ionic/vue';
        
import PageHeader from "@/components/PageHeader.vue";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";

import DesignPreQuestionModal from '@/components/DesignPreQuestionModal.vue';

// services
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';
import DesignService from '@/services/DesignService';

export default {
  name: 'DesignListPage',
  components: { IonPage, IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,
                IonToolbar, IonHeader, IonTitle, IonButtons, IonButton, IonIcon, IonList, IonItem, IonLabel,
                IonItemSliding, IonItemOptions, IonItemOption,
                PageHeader, LoadingSkeleton, },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const { openModal, presentPrompt, presentToast, formatDate, } = utils();
    const openDesignPreQuestionModal = async () => await openModal(DesignPreQuestionModal, { designId: null });

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingUserDesigns);
    const userDesigns = computed(() => store.getters.orderedUserDesigns);

    const deleteDesign = async (designId: any) => {
      presentPrompt("確認刪除此設計?", async () => {
        const loading = await loadingController.create({});
        await loading.present();
        DesignService.deleteDesign(designId);
        store.commit('deleteDesign', designId);
        loading.dismiss();
        presentToast( '已成功刪除', 2000 );
      });
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, trashOutline, createOutline,

      // variables
      loading, userDesigns,

      // methods
      t, openDesignPreQuestionModal, deleteDesign, formatDate,
    }
  }
}

